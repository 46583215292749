import 'slick-carousel';
import { BaseWidget } from './base-widget';

export class Carousel extends BaseWidget {
    static NAME = 'carousel';

    constructor(element) {
        super(element);

        this.inner = $('[data-carousel-inner]', this.element);
        this.wrapper = $(`[${this.element.data('wrapper')}]`);
        this.arrows = $('[data-carousel-arrows]', this.element);
        this.dots = $('[data-carousel-dots]', this.element);
        this.counter = $('[data-carousel-counter]', this.element);
        this.nav = this.element.data('nav');
        this.fade = this.element.data('fade');
        this.centerMode = this.element.data('center-mode');
        this.infinite = this.element.data('infinite');
        this.autoplay = this.element.data('autoplay');
        this.timeout = this.element.data('timeout');
        this.variableWidth = this.element.data('variable-width');
        this.adaptiveHeight = this.element.data('adaptive-height');
        this.amount = this.element.data('amount');
		this.id = this.element.data('id');

        if (this.arrows) {
            this.showArrows = true;
        }

        if (this.nav) {
            this.nav = `.carousel--${this.id}-${this.nav} > .carousel__inner`;
        } else {
            this.nav = false;
        }
    }

    init() {
        this.inner.slick({
            infinite: false,
            slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: true,
            mobileFirst: true,
            arrows: this.arrows ? true : false,
            appendArrows: this.arrows,
            dots: this.dots ? true : false,
            appendDots: this.dots,
            draggable: true,
            asNavFor: this.nav,
            fade: this.fade ? true : false,
            cssEase: 'linear',
            lazyLoad: this.lazyload,
            centerMode: this.centerMode ? true : false,
            centerPadding: this.margin,
            focusOnSelect: true,
            infinite: this.infinite ? true : false,
            adaptiveHeight : this.adaptiveHeight ? true : false,
            variableWidth: this.variableWidth ? true : false,
            // pauseOnDotsHover: this.pauseOnHover ? true : false,
            autoplay: this.autoplay ? true : false,
            autoplaySpeed: this.timeout,
            speed: 500,
            prevArrow: $('.c-carousel__arrow--prev', this.element),
            nextArrow: $('.c-carousel__arrow--next', this.element),
		});
    }
}
