import { throttle } from 'lodash';
import { BaseWidget } from './base-widget';

export class Header extends BaseWidget {
    static NAME = 'header';

    constructor(element) {
        super(element);
        this.navItems = $('[data-nav-item]', this.element);
        
        this.searchtoggle = $('[data-search-toggle]', this.element);
        this.searchbar = $('[data-search-bar]', this.element);
    }

    init() {
        window.addEventListener('scroll', throttle(this.handleScroll.bind(this), 20));
        // this.hideHeader();

        this.searchtoggle.on('click', (e) => {
            this.searchbar.toggleClass('is-active');
        });
    }

    handleScroll() {
        const scrollPosY = window.pageYOffset | document.body.scrollTop;

        if (scrollPosY > 0) {
            this.element.addClass('is-scrolled');
            $('body').addClass('is-scrolled');
            
            $('.c-usps').addClass('is-scrolled');
            $('.c-logo').addClass('is-scrolled');
            $('.o-section--header-images__element').addClass('is-scrolled');
            $('.menu-overlay__nav').addClass('is-scrolled');
            $('.c-site__main').addClass('is-scrolled');
            
        } else {
            this.element.removeClass('is-scrolled');
            $('body').removeClass('is-scrolled');
            $('.c-usps').removeClass('is-scrolled');
            $('.c-logo').removeClass('is-scrolled');
            $('.o-section--header-images__element').removeClass('is-scrolled');
            $('.menu-overlay__nav').removeClass('is-scrolled');
            $('.c-site__main').removeClass('is-scrolled');
        }
    }

    hideHeader() {
        let scrollTimeOut = true,
        lastYPos = 0,
        yPos = 0,
        yPosDelta = 5,
        nav = $('.c-header'),
        navHeight = nav.outerHeight(),
        setNavClass = function() {
            scrollTimeOut = false;
            yPos = $(window).scrollTop();

            if(Math.abs(lastYPos - yPos) >= yPosDelta) {
                if (yPos > lastYPos && yPos > navHeight){
                    nav.addClass('is-hidden');
                } else {
                    nav.removeClass('is-hidden');
                }
                lastYPos = yPos;
            }
        };

        $(window).scroll(function(e) {
            scrollTimeOut = true;
        });

        setInterval(function() {
            if (scrollTimeOut) {
                setNavClass();
            }
        }, 250);
    }
}
