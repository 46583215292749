import * as $ from 'jquery';
import { BaseWidget } from './base-widget';

export class Dropdown extends BaseWidget {
    static NAME = 'dropdown';

    // private dropdownId: string;
    // private target: JQuery;
    // private isOpen: boolean = false;

    constructor(element) {
        super(element);

        this.dropdownId = this.element.data('dropdown');
        this.target = $(`[data-dropdown-target="${this.dropdownId}"]`);
    }

    init() {
        this.element.on('click', (e) => {
            e.preventDefault();

            // if (this.isOpen) {
            //     this.close();
            // } else {
            //     this.open();
            // }

            this.toggle();
        });
        
        // if ('ontouchstart' in window || 'ontouch' in window ) {
        //     this.element.on('click', (e) => {
        //         e.preventDefault();
    
        //         if (this.isOpen) {
        //             this.close();
        //         } else {
        //             this.open();
        //         }
        //     });
        // }
    }
    //Current code
    // open() {
    //     this.isOpen = true;
    //     this.element.addClass('is-open');
    //     this.target.addClass('is-open');    
    //     this.target.parent().addClass('is-open');
    // }

    // close() {
    //     this.isOpen = false;
    //     this.element.removeClass('is-open');
    //     this.target.removeClass('is-open');
    //     this.target.parent().removeClass('is-open');
    // }

    //Refactored
    toggle() {
        this.element.toggleClass('is-open');
        this.target.toggleClass('is-open');
        this.target.parent().toggleClass('is-open');
    }
}
